import * as Sentry from "@sentry/vue";
import * as Package from "../../package.json";

export const setSentry = (app) => {
  if (process.env.NODE_ENV != "development") {
    Sentry.init({
      app,
      release: "app@" + Package.version,
      dsn: "https://d28dd2c99ba545eea5efcf2cea5b8588@o182130.ingest.sentry.io/5720062",
      ignoreErrors: [
        "401", 
        "403", 
        "network error", 
        "Network Error",
        "load failed", 
        "Request aborted", 
        "Non-Error promise rejection captured with value",
        "Failed to fetch dynamically imported module",
        "error loading dynamically imported module",
        "o.canvas is null"
      ],
      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 1.0,
      attachStacktrace: true,
    });
  }
};