<template>
  <div class="widget-controller">
    <div v-if="!show"
         class="icon widgets blue floating-icon d-none d-sm-flex"
         @click="show = true" />
    <InfoCard v-else>
      <template #header>
        <div class="card__header__title">
          <div class="widgets blue card__header__title__icon icon" />
          <h3 class="card__header__title__text">
            {{ t('summary.card.widgets.title') }}
          </h3>
          <div class="close-wrapper"
               @click="show = false">
            <div class="icon company-deleted blue" />
          </div>
        </div>
      </template>
      <template #content>
        <div class="container-fluid">
          <draggable v-if="widgets"
                     v-model="widgets" 
                     class="row"
                     item-key="key"
                     :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
                     v-bind="dragOptions"
                     @change="save">
            <template #item="{element}">
              <div class="col-12 p-initial"
                   :class="element.class">
                <div class="drag-item">
                  <Checkbox :model-value="isShow(element.name)"
                            :input-id="element.name"
                            :binary="true"
                            @input="updateCheck(element.name)" />
                  <label :for="element.name">{{ element.title }}</label>
                  <div class="icon grip-vertical" />
                </div>									
              </div>							
            </template>
          </draggable>
        </div>
      </template>
    </InfoCard>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, onMounted } from "vue";
  import { t } from "@/logic";
  import draggable from "vuedraggable";
  import { useEventBus } from "@vueuse/core";

  interface IWidget {
    name: string,
    show: boolean,
  }

  const emit = defineEmits<{
    (e: "update", widgets: Array<IWidget>): void;
  }>();

  const props = defineProps<{
    list: Array<IWidget>,
    group: string
  }>();

  const bus = useEventBus<string>("widgets/show");
  const show = ref(false);
  const widgets = ref<Array<IWidget>>([]);
  const dragOptions = computed(() => {
    return {
      animation: 0,
      disabled: false,
      ghostClass: "ghost",
    };
  });

  bus.on((name, value) => {
    updateCheck(name, value);
  });

  async function load(force = false) {
    const fromSave = JSON.parse(localStorage.getItem("widgets-" + props.group)) as Array<IWidget> || [] as Array<IWidget>;
    //const data = await http.get("/api/widgets");
   
    if (fromSave.length == props.list.length) {
      let newList = Array<IWidget>();
      fromSave.forEach(newItem => {
        let oldItem = widgets.value.find(x => x.name == newItem.name);
        if (oldItem) {
          oldItem.show = newItem.show;
          newList.push(oldItem);
        }                
      });
      widgets.value = newList;
    }
      
    emit("update", widgets.value);
  }

  function save() {
    const toSave = widgets.value.map((x) => <IWidget>{ name: x.name, show: x.show });
    localStorage.setItem("widgets-" + props.group, JSON.stringify(toSave));
    //http.post("/api/widgets", widgets.value);
    emit("update", widgets.value);
  }

  function isShow(name) {
    return widgets.value.find(x => x.name == name).show;
  }

  function updateCheck(name, value = null) {
    let item = widgets.value.find(x => x.name == name);
    item.show = value || !item.show;
    save();
  }

  onMounted(async () => {
    widgets.value = props.list;
    load(true);
  });
</script>

<style lang="scss" scoped>
.drag-item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin: 0.5rem !important;
  padding: 1rem !important;
  border: 1px solid #CDCED2 !important;
	cursor: pointer;
}


.close-wrapper {
  cursor: pointer;
  & .company-deleted {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 3rem;
  }
}

.widget-controller {
  position: fixed;
  right: 8rem;
  bottom: 2rem;
  z-index: $z-index-widget-controller;

  & .floating-icon {
    width: 5rem;
    font-size: 2.4rem;
    background-color: white;
    border: 1px solid $color-blue;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    cursor: pointer;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;

    &.blue {
      color: $color-blue;
      text-align: center;
    }

    &::before {
      margin: auto;
    }
  }

  .card {
    max-width: 60rem;

    @include media-breakpoint-down(md) {
      max-width: 35rem;
    }

    & .icon {
      width: initial;
      height: initial;
    }

    & .close-wrapper {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    &__header {
      &__title {
        margin-bottom: 2rem;
      }
    }
  }
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  background: #c8ebfb;
  opacity: 0.5;
}
</style>
