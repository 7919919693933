<template>
  <div>
    <SearchPage :icon="(props.type == 'marketing' ? 'marketing-search' : 'search-detailed')"
                :title="loading ? '' : data.title.prefix + ' ' + data.title.value">
      <template #controls>
        <Button :label="t('marketingResults.buttons.modifySearch')"
                size="small"
                style="margin-right: 1rem"
                @click="modifySearch" />
        <Button :label="t('marketingResults.buttons.newSearch')"
                size="small"
                style="margin-right: 1rem"
                @click="newSearch" />
        <Button v-if="props.type == 'marketing'"
                :label="t('marketingResults.favourite')"
                icon="icon favourite-add"
                size="small"
                style="margin-right: 1rem" 
                @click="favoriteOpen = !favoriteOpen" />
        <Button v-if="props.type == 'marketing'"
                :label="t('marketingResults.export')"
                icon="icon export"
                size="small"
                style="margin-right: 1rem" 
                @click="onExport" />

        <div v-show="favoriteOpen"
             class="mb-3 favorite-box">
          <div class="co-input-marketing">
            <label>{{ t('marketingResults.favorite.label') }}</label>
            <input v-model="favorite"
                   type="text"
                   class="form-control">
          </div>
          <div class="d-flex justify-content-end">
            <div class="button button--primary button--small ml-auto"
                 @click="addFavorite">
              {{ t('marketingResults.favorite.save') }}
            </div>
          </div>
        </div>  
      </template>
      <template #content>
        <Results :columns="columns"
                 :items="data.results"
                 :loading="loading"
                 :meta="meta"
                 :no-result-message="t('marketingResults.noResult')"
                 :new-search-path="props.type + '.search'"
                 :per-page="[10, 20, 50]"
                 @on-order="onOrder"
                 @page-change="pageChange">
          <template #headerLeft>
            <div v-if="props.type == 'marketing'"
                 class="d-block">
              <Dropdown v-model="customOrder"
                        :options="meta.orderOptions"
                        option-label="label"
                        :placeholder="t('customOrder.sorting')"
                        @change="onOrder(customOrder)" />
            </div>
          </template>
        </Results>
        <MarketingExport v-if="props.type == 'marketing'"
                         id="export" 
                         ref="exporter" 
                         style="max-width: 120rem; margin: 5rem auto;"
                         :open="showExport" />
      </template>
    </SearchPage>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, nextTick, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { http, t } from "@/logic";

  const props = defineProps(["type"]);

  const route = useRoute();
  const router = useRouter();
  const favoriteOpen = ref(false);
  const showExport = ref(false);
  const loading = ref(false);
  const favorite = ref("");
  const columns = ref([
    {
      value: t("marketing.columns.name"),
      prop: "name",
      type: "url",
      width: "25%",
      orderable: true,
      class: "ellipsis",
    },
    {
      value: t("marketing.columns.address"),
      prop: "address",
      width: "25%",
      orderable: true,
      class: "ellipsis",
    },
    {
      value: t("marketing.columns.registrationNumber"),
      prop: "registrationNumber",
      orderable: true,
      width: "10%",
    },
    {
      value: t("marketing.columns.taxNumber"),
      prop: "taxNumber",
      orderable: true,
      width: "10%",
    },
    {
      value: t("marketing.columns.status"),
      prop: "status",
      type: "icon",
      orderable: true,
      width: "20%",
    },
    {
      value: t("marketing.columns.marketing"),
      prop: "marketing",
      type: "marketing",
      width: "10%",
    },
  ]);
  const data = ref({
    items: [],
    title: "",
    price: 0,
  } as any);
  const meta = ref({
    orderBy: "",
    order: "",
    currentPage: 1,
    perPage: 10,
    count: 0,
  } as any);
  const customOrder = ref({} as any);
  const exporter = ref(null);

  function newSearch() {
    router.push({
      name: "marketing.search",
    });
  }

  function modifySearch() {
    router.push({
      name: "marketing.search",
      params: { id: route.params.id },
    });
  }

  async function load() {
    let query =
      `?orderBy=${meta.value.orderBy}&order=${meta.value.order}&currentPage=${meta.value.currentPage}` +
      `&perPage=${meta.value.perPage}`;
    const searchType = props.type == "marketing" ? "marketing" : "advanced-search";
    await http.fetchWithLoading(data, loading, `/api/${searchType}/${route.params.id}/results${query}`);
    meta.value.count = data.value.meta.count;
    meta.value.orderOptions = data.value.meta.orderOptions;
    showExport.value = meta.value.count > 0;
  }

  async function onOrder(order) {
    meta.value.currentPage = 1;
    meta.value.orderBy = order.orderBy;
    meta.value.order = order.order;
    if(order != customOrder.value) {
      customOrder.value = {};
    }    
    await load();
  }

  async function pageChange(event) {
    meta.value.currentPage = event.page + 1;
    meta.value.perPage = event.rows;
    await load();
  }

  async function addFavorite() {
    await http.post("/api/favorites/add", {
      name: favorite.value,
      requestId: route.params.id,
    });
    await router.push({ name: "favorites" });
  }

  function onExport() {
    document.getElementById("export").scrollIntoView();
  }

  onMounted(async () => {    
    await load();

    await nextTick();

    exporter.value?.setPrice(data.value.price);		
  });
</script>

<style lang="scss" scoped>
.favorite-box {
	position: absolute;
	z-index: 999;
  padding: 2rem;
  background-color: #fff;
  border: 1px solid $color-border-primary;
  border-radius: 10px;

  & .input-box {
    padding: 10px;
    font-size: 1.4rem;
    text-align: center;
    border: 1px solid $color-primary;
    border-style: dashed;
    border-radius: 10px;
  }

  & .example {
    font-size: 1.4rem;
  }
}
</style>
